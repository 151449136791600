import React from "react";
import { Button, Typography, Result } from "antd";
import styles from "../styles/styles.js";

const { Paragraph } = Typography;

function goGoula() {
  window.open(
    "https://www.linkedin.com/company/goula-agencia/about/",
    "_blank"
  );
}

const Success = (setStep, ctx) => {
  window.scrollTo(0, 0);

  return (
    <div
      style={{
        backgroundColor: "white",
        paddingRight: "7%",
        paddingLeft: "7%",
        paddingTop: "7%",
        paddingBottom: "7%",
        borderRadius: "10px",
        textAlign: 'center'
      }}
    >
      <Result status="success" />
      <div style={styles.speaker_name}>Registro completado</div>
      <Paragraph
        style={styles.question_info}
        ellipsis={{ rows: 5, expandable: true, symbol: "more" }}
      >
       Gracias por tu registro. Recibiras a tu correo un link con acceso al webinar. Ingresar el email que registraste puede ser requerido para acceder.
      </Paragraph>
      <Button
        type="primary"
        onClick={() => goGoula()}
        shape="round"
        size="large"
        style={{
          minWidth: "280px",
          minHeight: "50px",
          borderRadius: "5px",
          background: "#49ebf8",
          borderColor: "#49ebf8",
        }}
        primary
      >
        Siguenos en LinkedIn
      </Button>
      <div>
        <script
          src="https://platform.linkedin.com/in.js"
          type="text/javascript"
        >
          {" "}
          lang: en_US
        </script>
        <script
          type="IN/FollowCompany"
          data-id="1337"
          data-counter="bottom"
        ></script>
      </div>
    </div>
  );
};

export default Success;
