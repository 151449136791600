import "./fonts.css";
import naranja from "../assets/img/naranja.png";
import textura from "../assets/img/textura.png";
import textura_d from "../assets/img/textura_d.png";
import artboard1 from "../assets/img/artboard1.png";
import artboard2 from "../assets/img/artboard2.png";
import artboard3 from "../assets/img/artboard3.png";

const styles = {
  themes_row: {
    maxWidth: "100%",
    paddingLeft: "0%",
    paddingRight: "0%",
    paddingBottom: "5%",
    paddingTop: "0%",
    textAlign: "center",
    backgroundColor: "#f7f7f7",
    backgroundImage: `url(${textura_d})`,
    backgroundPosition: "top left",
    backgroundRepeat: "no-repeat",
  },
  left_container: {
    backgroundColor: "orange",
    textAlign: "left",
    paddingTop: "15vh",
    paddingBottom: "10vh",
    paddingLeft: "5%",
    paddingRight: "5%",
    minHeight: "90vh",
    backgroundImage: `url(${naranja})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  header: {
    lineHeight: '1.25',
    fontFamily: '"Gotham", sans-serif',
    color: "white",
    fontSize: 45,
    textTransform: "uppercase",
  },
  header_2: {
    fontWeight:500,
    fontFamily: '"GothamHTF Book", sans-serif',
    color: "white",
    fontSize: 35,
    lineHeight: '1.25',
    textTransform: "uppercase",
  },
  pre_header: {
    fontFamily: '"GothamHTF Book", sans-serif',
    color: "white",
  },
  navs: {
    fontFamily: '"GothamHTF Book", sans-serif',
    color: "#d50046",
    paddingRight: "8%",
  },
  sub_header: {
    paddingTop: 20,
    paddingLeft: 0,
    paddingRight: 10,
    fontFamily: '"Roboto", sans-serif',
    color: "white",
    fontSize: 20,
  },
  speakers_container: {
    textAlign: "center",
    paddingTop: "0%",
    paddingLeft: "25%",
    paddingRight: "25%",
    background: "white",
    minHeight: "90vh",
  },
  bio_container: {
    textAlign: "left",
  },
  bio_image: {
    width: "80%",
    paddingTop: "0%",
    textAlign: "center",
    paddingBottom: "5%",
  },
  speaker_title: {
    paddingLeft: "5%",
    paddingRight: "5%",
    fontFamily: '"Gotham", sans-serif',
    color: "#f49825",
    textTransform: "uppercase"
  },
  speaker_name: {
    paddingLeft: "5%",
    paddingRight: "5%",
    paddingBottom: "3%",
    fontSize: 27,
    fontFamily: '"Gotham", sans-serif',
    color: "#f90047",
  },
  speaker_bio: {
    paddingLeft: "5%",
    paddingRight: "5%",
    textAlign: "left",
    fontSize: 16,
    maxWidth: "80%",
    fontFamily: '"Roboto", sans-serif',
    color: "#000",
  },
  question_info: {
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: "center",
    fontSize: 16,
    maxWidth: "100%",
    fontFamily: '"GothamHTF Book", sans-serif',
    color: "#000",
  },
  speakers_header: {
    width: "100%",
    paddingLeft: "5%",
    paddingTop: "3%",
    paddingBottom: "10px",
    fontFamily: '"Roboto", sans-serif',
    color: "#f90047",
    fontSize: 35,
    textAlign: "left",
    backgroundColor: "#f7f7f7",
  },
  form_title: {
    fontSize: 27,
    fontFamily: '"Alata", sans-serif',
    color: "#f90047",
  },
  themes_container: {
    background: "#0000FF",
    textAlign: "center",
  },
  themes_title: {
    paddingTop: "3%",
    paddingBottom: "0%",
    fontFamily: '"Gotham", sans-serif',
    color: "#f90047",
    fontSize: 35,
    textAlign: "left",
    paddingLeft: "4%",
    width: "100%",
  },
  themes_bullets: {
    paddingLeft: "0%",
    fontSize: 27,
    fontFamily: '"Roboto", sans-serif',
    color: "#f90047",
  },
  li_space: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  li_space_f: {
    paddingTop: 0,
    paddingBottom: 10,
  },
  header_theme: {
    paddingLeft: "10%",
    textAlign: "left",
    fontFamily: '"Gotham", sans-serif',
    color: "#f49825",
  },
  padding_speakers: {
    marginLeft: "0%",
  },
  form_desc: {
    paddingTop: 20,
    paddingLeft: 0,
    paddingRight: 10,
    fontFamily: '"Roboto", sans-serif',
  },
  input_title: {
    color: '#565352',
    fontFamily: '"GothamHTF Book", sans-serif',
  },
  footer: {
    textAlign: "right",
    backgroundColor: "#f7f7f7",
    backgroundImage: [`url(${artboard2})`, `url(${textura})`],
    backgroundPosition: ["center left", "bottom left"],
    backgroundRepeat: "no-repeat",
    backgroundSize: ["20%", "auto"],
  },
  footer_image: {
    width: "65%",
    paddingTop: "0%",
    textAlign: "right",
    paddingBottom: "1%",
  },
  footer_line: {
    width: "80%",
    paddingTop: "0%",
    textAlign: "right",
    marginLeft: "0%",
  },
  line_divider: {
    width: "85%",
    paddingTop: "0%",
    textAlign: "left",
    marginLeft: "0%",
  },
  footer_text: {
    fontWeight: "100",
    fontFamily: '"Gotham HTF Light", sans-serif',
    fontSize: 25,
    width: "65%",
    paddingTop: "0%",
    textAlign: "right",
    paddingRight: "5%",
  },
  footer_bold: {
    fontWeight: "100",
    fontFamily: '"Gotham", sans-serif',
    fontSize: 25,
    width: "65%",
    paddingTop: "0%",
    textAlign: "right",
  },
};

export default styles;
