import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { Form, Input, Button, Typography, Layout } from "antd";
import nookies from "nookies";
import styles from "../styles/styles.js";

const { Content } = Layout;
const { Paragraph } = Typography;

const WebinarQuestion = ({ setStep, ctx }) => {
  window.scrollTo(0, 0);
  const cookies = nookies.get(ctx);
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = useState("optional");
  const onRequiredTypeChange = ({ requiredMark }) => {
    setRequiredMarkType(requiredMark);
  };

  const formik = useFormik({
    initialValues: {
      question: "",
    },
    onSubmit: (values) => {
      submitQuestion(values);
    },
  });

  function submitQuestion(values) {
    console.log(cookies.email);
    var payload = {
      email: cookies.email,
      data: values,
    };
    axios
      //.post(`http://localhost:5000/submitquestion`, payload)
      .post(
        `https://9imym8clge.execute-api.us-west-2.amazonaws.com/dev/submitquestion`,
        payload
      )
      .then((res) => {
        console.log(res);
        if (res.data === "done") {
          setStep(4);
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Error verifique su conexión");
      });
  }

  return (
    <div
      style={{
        backgroundColor: "white",
        paddingRight: "7%",
        paddingLeft: "7%",
        paddingTop: "7%",
        paddingBottom: "7%",
        borderRadius: "10px",
        textAlign: 'center'
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ requiredMark }}
        onValuesChange={onRequiredTypeChange}
        requiredMark={requiredMark}
        size="large"
      >
        <div style={styles.speaker_name}>¿Tienes alguna pregunta?</div>
        <Paragraph
          style={styles.question_info}
          ellipsis={{ rows: 5, expandable: true, symbol: "more" }}
        >
          Escribe anticipadamente tu pregunta relacionada a los temas del
          webinar
        </Paragraph>
        <Form.Item label="Escribe tu pregunta ">
          <Input.TextArea
            name="question"
            onChange={formik.handleChange}
            value={formik.values.question}
          />
        </Form.Item>
        <Form.Item>
          <Button
            onClick={formik.handleSubmit}
            style={{
              borderRadius: "5px",
              background: "#f90047",
              borderColor: "#f90047",
            }}
            type="primary"
          >
            Finalizar Registro
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default WebinarQuestion;
