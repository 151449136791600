import React, { useState } from "react";
import david from "../assets/img/david.png";
import mariana from "../assets/img/mariana.png";
import bloque_gris_d from "../assets/img/bloque_gris_d.png";
import header from "../assets/img/empaques_header.png";
import styles from "../styles/styles.js";
import { Layout, Row, Col, Button, Typography } from "antd";
import { LinkedIn } from "react-linkedin-login-oauth2";
import line from "../assets/img/linea.png";
import axios from "axios";
import nookies from "nookies";
import artboard1 from "../assets/img/artboard1.png";
import artboard3 from "../assets/img/artboard3.png";
import ManualForm from "./manualform";
import WebinarQuestion from "./question";
import Success from "./success";

const Main = ({ ctx, step, setStep }) => {
  //window.scrollTo(0, 0);
  const props = { step, setStep };


  function goToWebinar() {
    window.open(
      "https://event.webinarjam.com/go/live/10/x489pf1t7sps9",
      "_blank"
    );
  }

  function register() {
    window.scrollTo(0, 0);
    setStep(2);
  }

  function handleSuccess(data) {
    console.log(data["code"]);
    sendLinkedinData(data["code"]);
  }

  function sendLinkedinData(values) {
    var payload = {
      data: values,
    };
    axios
      .post(
        `https://9imym8clge.execute-api.us-west-2.amazonaws.com/dev/get_linkedin_token`,
        payload
      )
      //.post(`http://localhost:5000/get_linkedin_token`, payload)
      .then((res) => {
        nookies.set(ctx, "email", res.data, {
          maxAge: 30 * 24 * 60 * 60,
          path: "/",
          SameSite: true,
        });
        setStep(3);
      })
      .catch((error) => {
        console.log(error);
        alert("Error verifique su conexión");
      });
  }

  function handleFailure(error) {
    console.log(error);
  }

  const page = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <h3 style={styles.pre_header}>WEBINAR</h3>
            <p>
              <span style={styles.header}>
                <b>Haz del etiquetado tu aliado</b>
              </span>
              <br />
              <span style={styles.header_2}>
                Evita sanciones y mantén <br />
                el valor de tu marca.
              </span>
            </p>
            <h2 style={styles.pre_header}>22 de septiembre, 11:00 hrs</h2>
            <p style={styles.sub_header}>
              Acompáñanos a este webinar en el que dos expertos en el tema, nos
              darán a conocer información importante sobre el nuevo etiquetado
              frontal en productos de la industria de alimentos y bebidas en
              México, así como las sanciones por no cumplir en tiempo y forma
              con la implementación de la NOM-051.
            </p>
            <br />
            <br />
          </div>
        );
      case 2:
        return <ManualForm {...props} />;
      case 3:
        return <WebinarQuestion {...props} />;
      case 4:
        return <Success {...props} />;
      default:
        return <h1>Error 404</h1>;
    }
  };

  return (
    <div className="site-layout-background">
      <Row>
        <Col xs={24} sm={24} md={12} style={styles.left_container}>
          {page()}
        </Col>
        <Col
          xs={0}
          sm={0}
          md={12}
          style={{
            textAlign: "center",
            backgroundColor: "white",
            backgroundImage: [`url(${artboard1})`, `url(${bloque_gris_d})`],
            backgroundPosition: ["top right", "top right"],
            backgroundSize: ["40%", "cover"],
            backgroundRepeat: "no-repeat",
            textAlignVertical: "bottom",
          }}
        >
          <div
            style={{
              textAlign: "left",
            }}
          >
            <img
              src={header}
              style={{
                width: "100%",
                textAlign: "left",
                paddingTop: "50%",
              }}
            />
          </div>
        </Col>
      </Row>
      <Row style={styles.themes_row}>
        <img src={line} style={styles.footer_line} />
        <div style={styles.themes_title}>
          <p>Temas</p>
        </div>
        <Col xs={24} sm={24} md={10} style={styles.col_decoration_l}>
          <h3 style={styles.header_theme}>ETIQUETADO</h3>
          <div
            style={{
              textAlign: "left",
              marginLeft: "10%",
              marginRight: "10%",
            }}
          >
            <br />
            <div style={styles.themes_bullets}>
              ¡No te equivoques! Evita multas y sanciones. Todo lo que debes
              saber.
            </div>
            <br />
            <br />
            <br />
          </div>
        </Col>

        <Col xs={24} sm={24} md={10}>
          <h3 style={styles.header_theme}>MARKETING</h3>
          <div
            style={{
              textAlign: "left",
              marginLeft: "10%",
              marginRight: "10%",
            }}
          >
            <br />
            <div style={styles.themes_bullets}>
              El éxito en marketing alimenticio pese a pandemias, normas y
              nuevas normalidades.
            </div>
            <br />
            <br />
          </div>
        </Col>

        <Col xs={24} sm={24} md={4}></Col>
      </Row>
      <Row
        style={{
          maxWidth: "100%",
          paddingLeft: "0%",
          paddingRight: "0%",
          paddingBottom: "0%",
          paddingTop: "2.5%",
          textAlign: "left",
          backgroundColor: "#f7f7f7",
        }}
      >
        <Col
          xs={24}
          sm={24}
          md={10}
          style={{
            textAlign: "left",
            paddingLeft: "5%",
            paddingRight: "5%",
          }}
        >
          <img src={mariana} style={styles.bio_image} />
          <div style={styles.bio_container}>
            <div style={styles.speaker_title}>INGENIERA EN ALIMENTOS</div>
            <p></p>
            <div style={styles.speaker_name}>Mariana Curiel</div>
            <p style={styles.speaker_bio}>
              Licenciada en biología. Fundadora de la Asociación Mexicana de
              Etiquetado, Etiquetado Nutrimental, Capacitación y Emprendimiento.
              Desde hace 14 años asesora a empresas nacionales y extranjeras en
              el desarrollo de marca, reformulación de producto, revisión de
              etiquetado y tabla nutrimental, evaluación sensorial, revisión de
              información comercial y sanitaria, así como con requerimientos de
              exportación. Ofrece cursos, conferencias y talleres a empresas
              privadas, universidades, cámaras de alimentos y gobierno.
            </p>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={10}
          style={{
            textAlign: "left",
            paddingLeft: "5%",
            paddingRight: "5%",
          }}
        >
          <div style={styles.padding_speakers}>
            <img src={david} style={styles.bio_image} />
            <div style={styles.bio_container}>
              <div style={styles.speaker_title}>Director de Goula, plataforma especializada en food marketing y negocios</div>
              <div style={styles.speaker_name}>David Roldán</div>
              <p style={styles.speaker_bio}>
                Licenciado en Comunicación. Especialista en marketing y negocios
                con certificación de Stanford University en Innovación y
                Emprendimiento. Entusiasta de la industria alimentaria, su
                prospectiva y las nuevas tendencias sociales y tecnológicas.
                Desde el departamento estratégico y creativo ha trabajado con
                marcas como Coca-Cola, Corona, Vitamin Water, Heineken, y
                Hershey’s. Hace 12 años fundó su propia empresa, New World
                Monkeys, semillero de compañías especializadas como Goula, punta
                de lanza de la industria alimentaria, y Salón Cervecero, marca
                de cerveza artesanal.
              </p>
            </div>
          </div>
        </Col>
        <Col xs={0} sm={0} md={4}>
          <img src={artboard3} style={styles.bio_image} />
        </Col>
      </Row>
    </div>
  );
};

export default Main;
