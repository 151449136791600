import React, { useState } from "react";
import "./App.css";
import logo from "./assets/img/logo.png";
import Main from "./pages/main";
import { Layout, Row, Col } from "antd";
import { LinkedinOutlined, FacebookOutlined } from "@ant-design/icons";
import styles from "./styles/styles.js";
import footer_decoration from "./assets/img/empaques_footer.png";
import line from "./assets/img/linea.png";

const App = () => {
  const { Header, Footer } = Layout;
  const [step, setStep] = useState(1);
  const props = { step, setStep };
  function goHome() {
    window.scrollTo(0, 0);
    setStep(1);
  }
  function goSuscribe() {
    setStep(2);
  }
  function goGoula() {
    window.open("http://goula.lat", "_blank");
  }

  function goGoulaLinkedin() {
    window.open(
      "https://www.linkedin.com/company/goula-agencia/about/",
      "_blank"
    );
  }

  function goGoulaFacebook() {
    window.open("https://www.facebook.com/somosgoula/", "_blank");
  }

  return (
    <Layout>
      <Header
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          backgroundColor: "#f1f1f1",
        }}
      >
        <Row style={{ zIndex: 100 }}>
          <Col xs={24} sm={24} md={12} style={{ textAlign: "left" }}>
            <a className="logo" onClick={() => goHome()}>
              <img src={logo} style={{ maxWidth: "250px" }} />
            </a>
          </Col>
          <Col xs={0} sm={0} md={12} style={{ textAlign: "right" }}>
            <a onClick={() => goSuscribe()} style={styles.navs}>
              Inscríbete
            </a>
            <a onClick={() => goGoula()} style={styles.navs}>
              Conoce Goula
            </a>
            <a onClick={() => goGoulaLinkedin()}>
              <LinkedinOutlined
                style={{
                  fontSize: 25,
                  paddingTop: 20,
                  color: "#d50046",
                  paddingRight: "3%",
                }}
              />
            </a>
            <a onClick={() => goGoulaFacebook()}>
              <FacebookOutlined
                style={{ fontSize: 25, paddingTop: 20, color: "#d50046" }}
              />
            </a>
          </Col>
        </Row>
      </Header>
      <Main {...props} />
      <Footer style={styles.footer}>
        <img src={footer_decoration} style={styles.footer_image} />
        <div style={{ textAlign: "right", width: "100%" }}>
          <img src={line} style={styles.footer_line} />
        </div>
        <br />
        <div style={{width: "100%", paddingTop: "0.5%", color: "#515352" }}>
          <a onClick={() => goGoulaLinkedin()}>
            <LinkedinOutlined
              style={{ fontSize: 30, paddingRight: 10, color: "#d50046" }}
            />
          </a>
          <a onClick={() => goGoulaFacebook()}>
            <FacebookOutlined
              style={{
                fontSize: 30,
                paddingLeft: 10,
                paddingRight: 15,
                color: "#d50046",
              }}
            />
          </a>
          <a style={{ color: "black" }}>
            <span style={styles.footer_text}>
              WWW.<span style={styles.footer_bold}>GOULA</span>.LAT
            </span>
          </a>
        </div>
        <br />
        <br />
        <br />
      </Footer>
    </Layout>
  );
};

export default App;
