import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import nookies from "nookies";
import {
  Form,
  Input,
  Button,
  Typography,
  Layout,
  Checkbox,
  Select,
} from "antd";
import axios from "axios";
import styles from "../styles/styles.js";

const { Content } = Layout;
const { Option } = Select;
const { Title, Paragraph } = Typography;
const validationSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  company: yup.string().required(),
  position: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.number().positive().integer(),
});

const ManualForm = ({ setStep, ctx }) => {
  function onFinish() {
    console.log("finished!");
  }
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = useState("optional");
  const [acceptTerms, setacceptTerms] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const onRequiredTypeChange = ({ requiredMark }) => {
    setRequiredMarkType(requiredMark);
  };
  const cookies = nookies.get(ctx);
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
      position: "",
      city: "",
      state: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      nookies.set(ctx, "email", values.email, {
        maxAge: 30 * 24 * 60 * 60,
        path: "/",
        SameSite: true,
      });
      setBtnLoading(true);
      submitData(values);
    },
  });
  const onCheckboxChange = (e) => {
    console.log(e.target.checked);
    setacceptTerms(e.target.checked);
  };
  function validationConverter(value) {
    value = Boolean(value);
    if (value == false) {
      return "success";
    } else {
      return "error";
    }
  }
  function submitData(values) {
    if (acceptTerms) {
      storeData(values);
    } else {
      setBtnLoading(false);
      alert("Debes aceptar los terminos y condiciones");
    }
  }

  function onChange(value) {
    formik.values.position = value;
  }

  function onChangeState(value) {
    formik.values.state = value;
  }

  function onBlur() {
    console.log("blur");
  }

  function onFocus() {
    console.log("focus");
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function storeData(values) {
    var payload = {
      data: values,
    };
    axios
      .post(
        `https://9imym8clge.execute-api.us-west-2.amazonaws.com/dev/subscribe`,
        payload
      )
      //.post(`http://localhost:5000/subscribe`, payload)
      .then((res) => {
        setStep(3);
      })
      .catch((error) => {
        setBtnLoading(false);
        alert("Error verifique su conexión");
      });
  }

  return (
    <div
      style={{
        backgroundColor: "white",
        paddingRight: "7%",
        paddingLeft: "7%",
        paddingTop: "7%",
        paddingBottom: "7%",
        borderRadius: "10px",
      }}
    >
      <Title style={styles.form_title}> Registro</Title>
      <Paragraph ellipsis={{ symbol: "more" }} style={styles.form_desc}>
        Ingresa los siguientes datos para registrarte al webinar
      </Paragraph>
      <Form
        form={form}
        layout="vertical"
        initialValues={{ requiredMark }}
        onValuesChange={onRequiredTypeChange}
        requiredMark={requiredMark}
        size="large"
      >
        <Form.Item
          style={styles.input_title}
          label="Nombre(s)"
          validateStatus={validationConverter(formik.errors.firstName)}
        >
          <Input
            placeholder="Alfredo"
            name="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
          />
        </Form.Item>
        <Form.Item
          style={styles.input_title}
          label="Apellidos"
          validateStatus={validationConverter(formik.errors.lastName)}
        >
          <Input
            placeholder="Lingüini"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
          />
        </Form.Item>
        <Form.Item
          style={styles.input_title}
          label="Telefono"
          validateStatus={validationConverter(formik.errors.phone)}
        >
          <Input
            placeholder="55-5555-5555"
            name="phone"
            onChange={formik.handleChange}
            value={formik.values.phone}
          />
        </Form.Item>
        <Form.Item
          style={styles.input_title}
          label="Email"
          validateStatus={validationConverter(formik.errors.email)}
        >
          <Input
            placeholder="chef_alfredo@gusteaus.fr"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
        </Form.Item>
        <Form.Item
          style={styles.input_title}
          label="Empresa"
          validateStatus={validationConverter(formik.errors.company)}
        >
          <Input
            placeholder="Gusteau's"
            name="company"
            onChange={formik.handleChange}
            value={formik.values.company}
          />
        </Form.Item>
        <Form.Item
          style={styles.input_title}
          label="Ciudad"
        >
          <Input
            placeholder="París"
            name="city"
            onChange={formik.handleChange}
            value={formik.values.city}
          />
        </Form.Item>
        <Form.Item
          style={styles.input_title}
          label="Estado"
        >
          <Select
            showSearch
            placeholder="Isla de Francia"
            optionFilterProp="children"
            onChange={onChangeState}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="Aguascalientes">Aguascalientes</Option>
            <Option value="Baja California">Baja California</Option>
            <Option value="Baja California Sur">Baja California Sur</Option>
            <Option value="Campeche">Campeche</Option>
            <Option value="Coahuila">Coahuila</Option>
            <Option value="Colima">Colima</Option>
            <Option value="Chiapas">Chiapas</Option>
            <Option value="Chihuahua">Chihuahua</Option>
            <Option value="CDMX">CDMX</Option>
            <Option value="Durango">Durango</Option>
            <Option value="Guanajuato">Guanajuato</Option>
            <Option value="Guerrero">Guerrero</Option>
            <Option value="Hidalgo">Hidalgo</Option>
            <Option value="Jalisco">Jalisco</Option>
            <Option value="México">México</Option>
            <Option value="Michoacán de Ocampo">Michoacán de Ocampo</Option>
            <Option value="Morelos">Morelos</Option>
            <Option value="Nuevo León">Nuevo León</Option>
            <Option value="Oaxaca">Oaxaca</Option>
            <Option value="Puebla">Puebla</Option>
            <Option value="Querétaro">Querétaro</Option>
            <Option value="Quintana Roo">Quintana Roo</Option>
            <Option value="San Luis Potosí">San Luis Potosí</Option>
            <Option value="Sinaloa">Sinaloa</Option>
            <Option value="Sonora">Sonora</Option>
            <Option value="Tabasco">Tabasco</Option>
            <Option value="Tamaulipas">Tamaulipas</Option>
            <Option value="Tlaxcala">Tlaxcala</Option>
            <Option value="Veracruz">Veracruz</Option>
            <Option value="Vicepresidente">Vicepresidente</Option>
            <Option value="Yucatán">Yucatán</Option>
            <Option value="Zacatecas">Zacatecas</Option>
          </Select>
        </Form.Item>
        <Form.Item
          style={styles.input_title}
          label="Cargo"
          validateStatus={validationConverter(formik.errors.position)}
        >
          <Select
            showSearch
            placeholder="Selecciona un cargo"
            optionFilterProp="children"
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="Administrador">Administrador</Option>
            <Option value="Analista">Analista</Option>
            <Option value="Asesor">Asesor</Option>
            <Option value="Asistente">Asistente</Option>
            <Option value="Auditor">Auditor</Option>
            <Option value="Auxiliar">Auxiliar</Option>
            <Option value="CEO">CEO</Option>
            <Option value="Científico">Científico</Option>
            <Option value="Comprador">Comprador</Option>
            <Option value="Consultor">Consultor</Option>
            <Option value="Coordinador">Coordinador</Option>
            <Option value="Desarrollador">Desarrollador</Option>
            <Option value="Director">Director</Option>
            <Option value="Doctor">Doctor</Option>
            <Option value="Ejecutivo">Ejecutivo</Option>
            <Option value="Encargado">Encargado</Option>
            <Option value="Especialista">Especialista</Option>
            <Option value="Estudiante">Estudiante</Option>
            <Option value="Formulador">Formulador</Option>
            <Option value="Gerente">Gerente</Option>
            <Option value="Ingeniero">Ingeniero</Option>
            <Option value="Investigador">Investigador</Option>
            <Option value="Jefe">Jefe</Option>
            <Option value="Líder">Líder</Option>
            <Option value="Nutriólogo">Nutriólogo</Option>
            <Option value="Planeador">Planeador</Option>
            <Option value="Presidente">Presidente</Option>
            <Option value="Productor">Productor</Option>
            <Option value="Profesional independiente">Analista</Option>
            <Option value="Profesor / Docente">Profesor / Docente</Option>
            <Option value="Propietario">Propietario</Option>
            <Option value="Representante">Representante</Option>
            <Option value="Responsable">Responsable</Option>
            <Option value="Saborista">Saborista</Option>
            <Option value="Secretaria">Secretaria</Option>
            <Option value="Socio">Socio</Option>
            <Option value="Subdirector">Subdirector</Option>
            <Option value="Subgerente">Subgerente</Option>
            <Option value="Superintendente">Superintendente</Option>
            <Option value="Supervisor">Supervisor</Option>
            <Option value="Técnico">Técnico</Option>
            <Option value="Tecnólogo">Tecnólogo</Option>
            <Option value="Vicepresidente">Vicepresidente</Option>
            <Option value="Verificador">Verificador</Option>
            <Option value="Otros">Otros</Option>
          </Select>
        </Form.Item>
        <div style={{ textAlign: "center" }}>
          <Form.Item
            name="agreement"
            checked={acceptTerms}
            onChange={onCheckboxChange}
          >
            <Checkbox>
              Acepto el uso mis datos con fines de contacto para Goula.lat
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              loading={btnLoading}
              onClick={formik.handleSubmit}
              type="primary"
              style={{
                borderRadius: "5px",
                background: "#d50046",
                borderColor: "#d50046",
              }}
            >
              Inscribirme
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ManualForm;
