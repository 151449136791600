import React, { Component }  from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

ReactDOM.render(
  <React.StrictMode>
    <Router basename="/webinars">
      <Switch>
        <Route path="/linkedin" component={LinkedInPopUp} />
        <Route path="/">
          <App />
        </Route>
        <Route path="/app">
          <App />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
